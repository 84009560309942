/*
 * ====================================================================
 * Grow Template
 * @package  Grow Template
 * @author   GrowGroup.Inc <info@grow-group.jp>
 * @license  MIT Licence
 * ====================================================================
 */

(function ($) {
  var growApp = function () {

  };

  /************************
   * please insert your code
   *************************/
  growApp.prototype.bannerPosition = function () {
    var banner = $('.c-banner-pop');
    $(window).bind("scroll", function() {
      scrollHeight = $(document).height();
      scrollPosition = $(window).height() + $(window).scrollTop();

      if ($(window).innerWidth() < 750){
        return false;
      }

      if ( (scrollHeight - scrollPosition) / scrollHeight <= 0.03) {
       banner.addClass('is-btm');
      } else{
        banner.removeClass('is-btm');
      }
    });
  }

  growApp.prototype.enterAnimation = function () {
    var $loader = document.querySelector(".c-main-visual");
    var $mainImage = document.querySelector('.c-main-visual__slider');
    var $mainTitle = document.querySelector('.c-main-visual__text');
    var $mainBox = document.querySelector('.c-main-visual__footer');


    function changeClass(el, className, time) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          el.classList.add(className);
          resolve(className);
        }, time);
      });
    }

    var loaderFunction = function() {

      if ($loader) {
        changeClass($mainImage, "is-image-visible", 700).then(function () {
          changeClass($mainTitle, "is-text-visible", 700).then(function () {
            changeClass($mainBox, "is-box-visible", 700).then(function () {
            });
          });
        });
      }
    };

    // first entry only
    if (!sessionStorage.getItem('loading')) {
      sessionStorage.setItem('loading', true);
      loaderFunction();
    } else {
      $loader.classList.add('is-animation-end');
    }

  };

  $(function () {
    var app = new growApp();
    app.bannerPosition();
    app.enterAnimation();
  });
})(jQuery);
