import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import Accordion from './app/accordion.js';
import Anchor from './app/anchor.js';
import FixedHeader from './app/fixedheader.js';
import HeightLine from './app/heightline.js';
import ResponsiveTable from './app/responsive-table.js';
import Slidebar from './app/slidebar.js';
import Tab from './app/tab.js';
import CurrentNav from './app/current-nav.js';
import buildFormat from './app/format.js';
import myScript from './scripts.js';
import OwlCarousel from 'owl.carousel';
import modaal from 'modaal';
import {scrollfire} from "./app/scrolltrigger.js";
import anime from 'animejs';

import fontAwesome from "font-awesome/scss/font-awesome.scss";
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css";
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css";
import modaalCss from 'modaal/dist/css/modaal.css';
import slick from "slick-carousel/slick/slick.min.js";
import slickCss from "slick-carousel/slick/slick.css";
import slickTheme from "slick-carousel/slick/slick-theme.css";
class ScrollReveal {
  constructor(options){
    this.options = options;
  }


  reveal(el, options, duration){
    $(el).css({
      visibility: "visible",
      opacity: 0,
    })
    scrollfire( el, function(){
      // console.log(el);
      var args = {
        targets: el,
        // opacity: 1,
        // translateY: -options.distance,
        // duration: 600
      }
      args = Object.assign(options,args);
      anime(args);
    }, {
      offset: window.innerHeight
    })
  }
}

class App {
  constructor() {
    this.Utils = new Utils();
    this.Accordion = new Accordion();
    this.Anchor = new Anchor();
    this.FixedHeader = new FixedHeader();
    this.HeightLine = new HeightLine();
    this.ResponsiveTable = new ResponsiveTable();
    this.Slidebar = new Slidebar();
    this.CurrentNav = new CurrentNav();
    this.Tab = new Tab();

    //SPメニューの高さ取得
    function menuHight() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }

      var $smpHeaderHeight = $('.l-header').height();
      var windowHeight = window.innerHeight;
      var winH = windowHeight - $smpHeaderHeight;

      console.log($smpHeaderHeight);

      //動かすターゲットを取得
      var targetSlidebarWrap = $('.c-slidebar-menu'),
        targetSlidebarMenu = $('.c-slidebar__parent'),
        targetSlidebarBtn = $('.c-slidebar-menu__parent');


      //いざ実行(クリックしたら処理を実行する)
      targetSlidebarBtn.on('click', function () {
        $('.c-slidebar-menu').toggleClass('is-active');

      });
    }

    //フッターメニューSPスライド
    function menuSlide() {
      var win = $(window).innerWidth();
      if (win > 750) {
        return false;
      }
      $('.l-footer__block').on('click', function () {
        $(this).children(".l-footer__menutitle").toggleClass('is-open');
        $(this).children(".l-footer__menulist.is-sub").slideToggle();
      })
    }

    //owlcarousel
    function owlCarousel() {
      var owls = $('.owl-carousel');
      if (owls.length === 0) {
        return false
      }
      //->スライダー
      owls.imagesLoaded(function () {
        $('.c-main-visual__slider').owlCarousel({
          items: 1,
          margin: 0,
          dots: true,
          loop: true,
          nav: false,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 400,
          autoWidth: false,
          autoHeight: false,
          center: true,
          animateOut: 'fadeOut',
        });
      });
      //->カード_カルーセル
      owls.imagesLoaded(function () {
        $('.js-card-slider').owlCarousel({
          margin: 0,
          dots: false,
          loop: true,
          nav: true,
          autoplayHoverPause: true,
          autoplay: true,
          autoplaySpeed: 500,
          autoWidth: false,
          autoHeight: false,
          center: true,
          // navText: ['<img src="../assets/images/icon-slider-prev.svg" />', '<img src="../assets/images/icon-slider-next.svg" />'],
          responsive: {
            // breakpoint from 0 up
            0: {
              items: 1,
            },
            // breakpoint from 750 up
            750: {
              items: 3,
            }
          }
        });
      });
    }
    //- スクロールリーバル
    function reveal() {
      function domEach(items, callback) {
        if (typeof items === "string") {
          var items = $(items);
        }
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          // callback = callback.bind(item)
          callback(i, item);
        }
      }

      window.sr = new ScrollReveal({duration: 600, mobile: true});
      var baseEasing = 'cubicBezier(0.175, 0.885, 0.32, 1.275)';
      var baseDistance = '8';
      domEach(".c-hero-block__item-image", function (key, item) {

        sr.reveal(item, {
          opacity: 1,
          duration: 2000,
          delay: 200 * key,
          translateY: [50, 0],
          easing: baseEasing
        });
      });
      domEach(".c-hero-block__item-content", function (key, item) {
        sr.reveal(item, {
          opacity: 1,
          duration: 3000,
          delay: 250 * key,
          translateY: [50, 0],
          easing: baseEasing
        });
      });



      // ここから　アニメーションが危ない
      domEach(".c-block-type__inner", function (key, item) {

        sr.reveal(item, {
          opacity: 1,
          duration: 3000,
          delay: 100 * key,
          translateY: [50, 0],
          easing: baseEasing
        });
      });
      domEach(".c-block-facirity__image", function (key, item) {
        sr.reveal(item, {
          opacity: 1,
          duration: 3000,
          delay: 100 * key,
          translateY: [50, 0],
          easing: baseEasing
        });
      });
      domEach(".c-gallery", function (key, item) {
        sr.reveal(item, {
          opacity: 1,
          duration: 3000,
          delay: 200,
          translateY: [50, 0],
          easing: baseEasing
        });
      });
    }





    // modaal
    function modaal() {
      // ====================
      // <HTML>
      // <a href="path/to/image.jpg" class="js-modal-image" data-modaal-desc="My Description">Show</a>
      // or
      // <button data-modaal-content-source="path/to/image.jpg" class="js-modal-image">Show</button>
      // ====================

      $('.js-modal-image').modaal({
        type: 'image'
      });
    }


    //slick-arousel
    function slickSlider() {
      $('.slider-for').slick({
        slidesToShow: 1,
        // slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        infinite: true,
        asNavFor: '.slider-nav',
        // initialSlide: 2,
        nextArrow:	'<div class="slick-prev"><img src="../assets/images/icon-gallery-prev@2x.png" alt="前へ"/></div>',
        prevArrow:	'<div class="slick-next"><img src="../assets/images/icon-gallery-next@2x.png" alt="次へ"/></div>'

      });
      $('.slider-nav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        infinite: true,
        arrow: false,
        // centerMode: true,
        focusOnSelect: true,
        // initialSlide: 2,

        responsive: [{
          breakpoint: 950,
          settings: {
            slidesToShow: 3,
          }
        }]
      });
      $('.slider-for').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if ($('.slider-nav .c-gallery__thumbnails-images').length < 5) {
          $('.slider-nav').slick('slickSetOption', 'centerMode', false, true);
          $('.slider-nav').slick('slickSetOption', 'focusOnSelect', false, false);
        }
      });
    }

    function slickSliderRoom() {
      var slider = '.js-slider';
      var thumbItem = '.js-thumb-item';
      var sliderMain = "";
      var thumb = '.js-thumb';



      $('.js-slider-main').each(function (i, elm) {
        var $slider_area = $(elm).parent();
        var $main_slide = $slider_area.find(".js-slider-main");
        var $thumb_slide = $slider_area.find(".js-thumb");


        // データ属性の数字を割り振る
        $thumb_slide.find('.js-thumb-item').each(function () {
          var index = $thumb_slide.find('.js-thumb-item').index(this);
          $(this).attr("data-index", index);
        });

        // 初期化
        $main_slide.on('init', function (slick) {
          $thumb_slide.find(".js-thumb-item:first-child").addClass('thumb-current');
        });

        // サムネイルをクリックした時スライダー切り替え
        $thumb_slide.find(".js-thumb-item").on('click', function () {
          var index = $(this).attr("data-index");
          $main_slide.slick("slickGoTo", index, false);
        });

        // サムネイル画像のカレントを切り替え
        $main_slide.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          $thumb_slide.find(".js-thumb-item").removeClass("thumb-current");
          console.log(currentSlide);
          $thumb_slide.find('.js-thumb-item[data-index="' + nextSlide + '"]').addClass("thumb-current");
        });

        // オプション
        $main_slide.slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          accessibility: false,
          dots: false,
          arrows: false,
          fade: true,
          responsive: [
            {
              breakpoint: 750,
              settings: {}
            },
          ]
        });
      });
    }

    function sliedbarButton() {
      var button = $('.c-slidebar-menu ul li a');

      button.on('click', function () {
        $('body').removeClass('is-slidebar-active');
        slidebar.isActive = false;
      });
    }

    $(function () {
      menuSlide();
      owlCarousel();
      modaal();
      reveal();
      slickSlider();
      slickSliderRoom();
      sliedbarButton();

    });
  }
}

window.GApp = new App();
